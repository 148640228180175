var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "app-container TianrunCall" },
    [
      _vm.course != 1
        ? _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-header",
                { staticStyle: { height: "auto" } },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { margin: "10px 0px" },
                      attrs: { gutter: 24 },
                    },
                    [
                      _c("Head", { attrs: { name: _vm.title } }),
                      _vm._v(" "),
                      _c("div", {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: [""],
                            expression: "['']",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { margin: "10px 0px" },
                          attrs: { span: 6.5 },
                        },
                        [
                          _c("span", [_vm._v("日期范围：")]),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              clearable: false,
                              type: "daterange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "timestamp",
                              size: "small",
                              "default-time": ["00:00:00", "23:59:59"],
                            },
                            on: { change: _vm.DataTime },
                            model: {
                              value: _vm.CallStatistics_time,
                              callback: function ($$v) {
                                _vm.CallStatistics_time = $$v
                              },
                              expression: "CallStatistics_time",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { margin: "10px 0px" },
                          attrs: { span: 3.5 },
                        },
                        [
                          _c("span", [_vm._v("所属部门:")]),
                          _vm._v(" "),
                          _c("el-cascader", {
                            attrs: {
                              options: _vm.bumenoptions,
                              clearable: "",
                              filterable: "",
                              "collapse-tags": "",
                              size: "small",
                              "expand-trigger": "hover",
                              placeholder: "请选择部门",
                              props: {
                                multiple: true,
                                value: "id",
                                label: "name",
                              },
                            },
                            on: { change: _vm.handleChange },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { margin: "10px 0px" },
                          attrs: { span: 3.5 },
                        },
                        [
                          _c("span", [_vm._v("顾问老师:")]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "medium",
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择",
                              },
                              on: { change: _vm.handleFilter },
                              model: {
                                value: _vm.CallStatistics_teacherid,
                                callback: function ($$v) {
                                  _vm.CallStatistics_teacherid = $$v
                                },
                                expression: "CallStatistics_teacherid",
                              },
                            },
                            _vm._l(
                              _vm.CallStatistics_teachername,
                              function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.realname,
                                    value: item.id,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { margin: "10px 0px" },
                          attrs: { span: 1 },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.search },
                            },
                            [_vm._v("搜索")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { margin: "10px 0px" },
                          attrs: { span: 1 },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["导出"],
                                  expression: "['导出']",
                                },
                              ],
                              attrs: { size: "small", type: "warning" },
                              on: { click: _vm.downLoadExcelFun },
                            },
                            [_vm._v("导出")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-main",
                [
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "multipleTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          id: "customerTable",
                          data: _vm.CallStatistics_list,
                          "row-key": "id",
                          border: "",
                          "tree-props": {
                            children: "children",
                            hasChildren: "hasChildren",
                          },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            width: "130",
                            label: "部门",
                            fixed: "",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "呼出次数" } },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "exhale_total_1",
                                label: "系统呼出次数",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "exhale_total_2",
                                label: "手机呼出次数",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "exhale_total_all",
                                label: "总呼出次数",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "呼出接通次数" } },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "connect_total_1",
                                label: "系统呼出接通次数",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "connect_total_2",
                                label: "手机呼出接通次数",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "connect_total_all",
                                label: "总呼出接通次数",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "呼出接通率" } },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "rate_1",
                                label: "系统呼出接通率",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "rate_2",
                                label: "手机呼出接通率",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "rate_avg",
                                label: "平均呼出接通率",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "呼出总通话时长" } },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "duration_1",
                                label: "系统呼出总通话时长",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "duration_2",
                                label: "手机呼出总通话时长",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "duration_all",
                                label: "呼出总通话时长",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "呼出平均通话时长" } },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "duration_avg_1",
                                label: "系统呼出平均时长",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "duration_avg_2",
                                label: "手机呼出平均通话时长",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "duration_avg_all",
                                label: "总呼出平均通话时长",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "0-30s通话次数" } },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "number_1_1", label: "系统" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "number_1_2", label: "手机" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "number_1_total", label: "总计" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "30-60s通话次数" } },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "number_2_1", label: "系统" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "number_2_2", label: "手机" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "number_2_total", label: "总计" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "60-100s通话次数" } },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "number_3_1", label: "系统" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "number_3_2", label: "手机" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "number_3_total", label: "总计" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "100-200s通话次数" } },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "number_4_1", label: "系统" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "number_4_2", label: "手机" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "number_4_total", label: "总计" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "200-300s通话次数" } },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "number_5_1", label: "系统" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "number_5_2", label: "手机" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "number_5_total", label: "总计" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "300s以上通话次数" } },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "number_6_1", label: "系统" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "number_6_2", label: "手机" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "number_6_total", label: "总计" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        ref: "tableLeft",
                        attrs: { id: "tableLeft" },
                        on: {
                          scroll: function ($event) {
                            return _vm.exterHandleScroll()
                          },
                        },
                      },
                      [
                        _c("div", {
                          staticStyle: { height: "10px" },
                          attrs: { id: "bodyLeft" },
                        }),
                      ]
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "boxImg",
              staticStyle: { width: "100%", padding: "20px" },
            },
            [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
            1
          ),
      _vm._v(" "),
      _c("my-export-excel", {
        ref: "downloadExcelRef",
        staticStyle: { display: "none" },
        attrs: { dialogType: 0 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }